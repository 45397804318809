<template>
  <div class="pb-2">
    <l-table
        ref="lTableRef"
        :module-name="MODULE_NAME"
        :table-columns="trashTableColumns"
        :create-page-url="{ name: 'home-catalog-create' }"
        :is-searchable="false"
        :table-config-options="getTableConfigOptions()"
        back-to-list-path="home-main-catalog-list-stocks"
    >
      <template #cell(name)="{ data }">
        <div
            v-b-tooltip.noninteractive.hover
            class="name-cell"
            :title="getValue(data, 'name') ? getValue(data, 'name') : '—'"
        >
          {{ getValue(data, 'name') ? getValue(data, 'name') : '—' }}
        </div>
      </template>
      <template #cell(brand)="{ data }">
        {{ getValue(data, 'brand') ? getValue(data, 'brand').name : '—' }}
      </template>
      <template #cell(category)="{ data }">
        {{ getValue(data, 'categories') ? getValue(data, 'categories').map(category => category.name).join(' , ') : '—' }}
      </template>
      <template #cell(model)="{ data }">
        {{ getValue(data, 'detail').model ? getValue(data, 'detail').model : '—' }}
      </template>
      <template #cell(is_active)="{ data }">
        {{ data.item.is_active === null ? '—' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(action)="{ data }">
        <feather-icon
            icon="LTrashIcon"
            size="30"
            style="padding: 2px 6px"
            class="cursor-pointer mr-1 border-dotted defaultIconColor"
            @click="remove(data.item)"
        />
        <feather-icon
            v-b-tooltip.noninteractive.hover
            :title="$t('Set to active')"
            icon="LLoadIcon"
            size="30"
            style="padding: 4px"
            class="feather-icon border-dotted cursor-pointer defaultIconColor"
            @click="restoreContact(data.item.id)"
        />
      </template>
    </l-table>
  </div>
</template>

<script>

// components
import config from '../catalogConfig'
import {BLink, VBTooltip} from "bootstrap-vue";
import LTable from "@/views/components/LTable/LTable.vue";
import Actions from "@/views/components/Actions.vue";
import {CATALOG_TYPE_COMPONENT, CATALOG_TYPE_CONTAINER} from "@/views/main/catalog/catalogType";
export default {
  name: 'StockTrashList',
  components: {Actions, LTable, BLink},
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  methods: {
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    renderIcon(data) {
      let iconP
      switch (data.type_id) {
        case CATALOG_TYPE_COMPONENT:
          iconP = 'LComponentIcon'
          break
        case CATALOG_TYPE_CONTAINER:
          iconP = 'LContainerIcon'
          break
        default:
          iconP = ''
      }
      return iconP
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`)
          .then(
              () => {
                this.refetchData()
              },
          )
    },
    restoreContact(data) {
      this.confirmNotification(this, {ids: [data], is_active: 1}, `${this.MODULE_NAME}/statusChange`, {
        title: 'Are you sure you want to reactivate this ?',
        text: '',
        confirmButtonText: 'Reactivate',
      })
          .then(() => {
            this.refetchData()
          })
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    getPhoneNumber(obj) {
      const data = JSON.parse(obj)

      return `${data.formatNational} ${data.cell_no_ext_val ? `Ext.${data.cell_no_ext_val}` : ''}`
    },
    getData(data, dataName) {
      const { item: { default_contact } } = data
      if (dataName === 'fullname') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.first_name} ${default_contact.last_name}` : '—'
      } if (dataName === 'cell_no') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.cell_no}` : '--—'
      }
      return '---'
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.MODULE_NAME}/stockInActiveList`,
      }
    },
  },
  setup() {
    const MODULE_NAME = 'catalogs'
    const { trashTableColumns } = config()
    return {
      MODULE_NAME,
      trashTableColumns,
    }
  },
}
</script>
